import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";
import { recordActivity } from "../utils/activity";

export default class extends Controller {
  static targets = ["modal", "timer"]
  static values = {
    key: String,
    starId: Number,
    userId: Number,
    amount: Number,
    username: String,
    activityMessage: String,
    activityType: String,
    trackableType: String,
    trackableId: Number,
    duration: Number,
    contact: String
  };

  connect() {
    this.boostOptions = {
      key: this.keyValue,
      amount: this.amountValue,
      name: "Bigfanbro",
      description: `Fan Pass – Boost any YouTuber for ${this.durationValue} days!`,
      image: "https://bigfanbro-landing.s3.ap-south-1.amazonaws.com/logo-icon.png",
      handler: this.handlePaymentSuccess.bind(this),
      prefill: {
        name: this.usernameValue,
        contact: this.contactValue
      },
      notes: {},
      theme: {
        color: "#FF0033"
      }
    };
    if (this.modalTarget) {
      this.bootstrapModal = new bootstrap.Modal(this.modalTarget);
    }
  }

  open() {
    if (this.bootstrapModal) {
      this.bootstrapModal.show();
    }
  }

  close() {
    if (this.bootstrapModal) {
      this.bootstrapModal.hide();
      this.recordIgnoreBoost();
    }
  }

  recordIgnoreBoost() {
    recordActivity({
      message: this.activityMessageValue,
      activityType: this.activityTypeValue,
      trackableType: this.trackableTypeValue,
      trackableId: this.trackableIdValue
    });
  }

  handlePaymentSuccess(response) {
    this.disableBoostBtn()
    this.disableBoostCardBtn()

    fetch(`/stars/${this.starIdValue}/star_boosts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "Accept": "text/vnd.turbo-stream.html"
      },
      body: JSON.stringify({
        star_boost:{
          razorpay_payment_id: response.razorpay_payment_id,
          user_id: this.userIdValue,
          amount_cents: this.amountValue
        }
      })
    })
    .then(response => response.text())
    .then(html => {
      this.bootstrapModal.hide();
      this.closeStarProfileCard();
      this.disableBoostBtn(false)
      this.disableBoostCardBtn(false)
      Turbo.renderStreamMessage(html);  // Manually process the Turbo Stream response
    })
    .catch(error => {
      console.error("Payment failed:", error);
      this.disableBoostBtn(false)
      this.disableBoostCardBtn(false)
    });
  }

  buyFanPass(event) {
    event.preventDefault();
    let rzp1 = new Razorpay(this.boostOptions);
    rzp1.open();
  }

  closeStarProfileCard(event) {
    if (event) event.preventDefault();
    const card = document.getElementById("star-boost-card-row");
    if (card) {
      card.remove();
    }
  }
  
  disableBoostBtn(value = true) {
    document.querySelectorAll(".boostPay").forEach(btn => btn.disabled = value);
  }

  disableBoostCardBtn(value = true) {
    document.querySelectorAll(".boostPayCard").forEach(btn => btn.disabled = value);
  }
}
