export function recordActivity({ message, activityType, trackableType, trackableId }) {
  return fetch('/user_activities', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
    },
    body: JSON.stringify({
      user_activity: {
        message,
        activity_type: activityType,
        trackable_type: trackableType,
        trackable_id: trackableId
      }
    })
  })
  .then(response => response.json())
  .then(() => {
    console.log("Activity recorded");
  })
  .catch(() => {
    console.error("Error recording activity");
  });
}
