import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "phone"];
  static values = { userVotes: Number, loggedIn: String, userClaim: String, userId: String, activityType: String, activityMessage: String };

  connect() {
    // Initialize modal only once when the controller connects
    if (this.modalTarget) {
      this.bootstrapModal = new bootstrap.Modal(this.modalTarget);
      if (this.userVotesValue > 0 && this.loggedInValue === "false" && this.userClaimValue === "false") {
        this.bootstrapModal.show();
      }
    }
  }

  open() {
    if (this.bootstrapModal) {
      this.bootstrapModal.show();
    }
  }

  close() {
    if (this.bootstrapModal) {
      this.bootstrapModal.hide();
      if (this.userVotesValue > 0 && this.loggedInValue === "false") {
        this.recordIgnoreSignup()
      }
    }
  }

  validateSubmit() {
    if (this.bootstrapModal && this.phoneTarget.value.length > 0 ) {
      this.bootstrapModal.hide();
    }
  }

  recordIgnoreSignup() {
    fetch('/user_activities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        user_activity: {
          message: this.activityMessageValue,
          activity_type: this.activityTypeValue
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log("Points claim recorded");
    })
    .catch((error) => {
      console.error("Error points claim record");
    });
  }
}
