import { Controller } from "@hotwired/stimulus"
import html2canvas from "html2canvas";

export default class extends Controller {
  static values = { url: String, shareableId: String, shareableType: String, shareName: String, screenshotDiv: String }

  connect() {}

  async share() {
    const screenshotArea = document.getElementById(`${this.screenshotDivValue}`);

    if (screenshotArea) {
      try {
        // Clone the element to avoid modifying the original DOM
        const clonedElement = screenshotArea.cloneNode(true);
        clonedElement.style.backgroundColor = "#fff";
        clonedElement.style.padding = "15px"; 
        clonedElement.style.boxSizing = "border-box"; // Ensure padding doesn't affect dimensions

        // Remove all image tags from the cloned element
        clonedElement.querySelectorAll("img").forEach(img => img.remove());

        document.body.appendChild(clonedElement);

        // Capture Screenshot
        const canvas = await html2canvas(clonedElement, {
          backgroundColor: "#ffffff", // Force white background
          useCORS: true // Fix issues with images
        });

        document.body.removeChild(clonedElement); // Remove the cloned element after capture

        const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/png"));
        const file = new File([blob], "screenshot.png", { type: "image/png" });

        if (navigator.share && navigator.canShare({ files: [file] })) {
          await navigator.share({
            files: [file],
            title: this.shareNameValue,
            text: this.shareNameValue,
            url: this.urlValue,
          });
          console.log("Shared successfully");
          this.createShareRecord();
          return;
        }
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      }
    }

    // If no shareable div is found or screenshot fails, fallback to normal URL sharing
    if (navigator.share) {
      navigator.share({
        title: this.shareNameValue,
        text: this.shareNameValue,
        url: this.urlValue,
      })
      .then(() => {
        console.log("Shared successfully");
        this.createShareRecord();
      })
      .catch((error) => console.error("Error sharing:", error));
    } else {
      alert("Sharing not supported on this device.");
    }
  }

  createShareRecord() {
    fetch('/shares', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        share: {
          share_url: this.urlValue,
          title: document.title,
          shareable_id: this.shareableIdValue,
          shareable_type: this.shareableTypeValue,
          verified: true
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log("Share recorded");
    })
    .catch((error) => {
      console.error("Error share record");
    });
  }
}
