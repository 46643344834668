import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { userId: String, message: String, type: String, trackableType: String, trackableId: String }

  connect() {}

  recordActivity(){
    fetch('/user_activities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        user_activity: {
          message: this.messageValue,
          activity_type: this.typeValue,
          trackable_type: this.trackableTypeValue,
          trackable_id: this.trackableIdValue
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log("Activity recorded");
    })
    .catch((error) => {
      console.error("Error activity");
    });
  }
}
