// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "@fortawesome/fontawesome-free/js/all";
import "chartkick/chart.js"
import Toastify from 'toastify-js'
import LocalTime from "local-time"
LocalTime.start()

// document.addEventListener("turbo:before-render", (event) => {
//   console.log("Turbo: before-render", event);
// });

// document.addEventListener("turbo:render", (event) => {
//   console.log("Turbo: render", event);
// });


// Create a custom helper function for toasts
window.showToast = function(message, type = '', duration = 2000) {
  const options = {
    text: message,
    duration: duration,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
    className: `toast-${type}`,
    style: {
      borderRadius: '8px',
      padding: '10px',
    }
  }
  
  if (type === 'success') {
    options.style.background = "linear-gradient(to right, #4caf50, #81c784)"; // Green gradient
  } else if (type === 'danger') {
    options.style.background = "linear-gradient(to right, #f44336, #e57373)"; // Red gradient
  } else if (type === 'error') {
    options.style.background = "#FF0033";
  } else if (type === 'info') {
    options.style.background = "linear-gradient(to right, #2196f3, #64b5f6)"; // Blue gradient
  } else if (type === 'warning') {
    options.style.background = "linear-gradient(to right, #ff9800, #ff5722)"; // Yellow to orange gradient
  } else {
    options.style.background = "linear-gradient(to right, #424242, #616161)"; // Dark gray to charcoal gradient for default
  }
  
  Toastify(options).showToast()
}


// Function to convert VAPID public key
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Function to register Service Worker and subscribe
export async function registerServiceWorkerAndSubscribe() {
  // --- 1. Register Service Worker ---
  if (!('serviceWorker' in navigator)) {
    console.log('Service Worker not supported by this browser.');
    return;
  }
  if (!('PushManager' in window)) {
    console.log('Push Messaging not supported by this browser.');
    return;
  }

  try {
    const registration = await navigator.serviceWorker.register('/service-worker.js');
    // console.log('Service Worker registered with scope:', registration.scope);

    // Wait for the Service Worker to become active
    await navigator.serviceWorker.ready;
    console.log('Service Worker active.');

    // --- 2. Request Notification Permission ---
    const permission = await window.Notification.requestPermission();
    // Possible values: 'granted', 'denied', 'default'
    if (permission !== 'granted') {
      console.log('Push Notification permission denied.');
      // Optionally: Inform the user how to enable permissions later
      return;
    }
    console.log('Notification permission granted.');

    // --- 3. Get VAPID Public Key from Server (or embed it) ---
    // It's often better to fetch this or embed it via Rails view
    // to avoid hardcoding if the key changes.
    // Example using a meta tag in your layout:
    const vapidPublicKeyMeta = document.querySelector("meta[name='vapid-public-key']");
    if (!vapidPublicKeyMeta) {
        console.error("VAPID public key meta tag not found.");
        return;
    }
    const vapidPublicKey = vapidPublicKeyMeta.content;
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

    // --- 4. Subscribe to Push Notifications ---
    // console.log('Subscribing to Push Notifications...');

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey
    });
    // console.log('User is subscribed:', subscription);

    // --- 5. Send Subscription to Backend ---
    await sendSubscriptionToServer(subscription);

  } catch (error) {
    console.error('Service Worker registration or Push Subscription failed:', error);
  }
}

// Function to send the subscription object to your Rails backend
async function sendSubscriptionToServer(subscription) {
  // Get CSRF token for Rails POST request
  const csrfToken = document.querySelector("meta[name='csrf-token']").content;

  try {
    const response = await fetch('/push_subscriptions', { // Your Rails endpoint
      method: 'POST',
      body: JSON.stringify({ subscription: subscription }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    });

    if (!response.ok) {
      throw new Error(`Server responded with ${response.status}`);
    }

    const responseData = await response.json();
    // console.log('Subscription sent to server successfully:', responseData);
    // Optionally: Update UI to show user is subscribed

  } catch (error) {
    console.error('Failed to send subscription to server:', error);
    // Optionally: Try unsubscribing locally if server failed
    // subscription.unsubscribe();
  }
}

// --- Trigger the process ---
// You might want to trigger this based on a user action (e.g., clicking a button)
// or automatically on page load after checking if already subscribed.
document.addEventListener('DOMContentLoaded', async () => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      
      if (registration) {
        const subscription = await registration.pushManager.getSubscription();
        
        if (subscription) {
          console.log("User is already subscribed to push notifications.");
          return; // Exit early if already subscribed
        }
      }

      // If not subscribed, register service worker & subscribe
      setTimeout(() => {
        registerServiceWorkerAndSubscribe();
      }, 20000);
    } catch (error) {
      console.error("Push notification subscription failed:", error);
    }
  } else {
    console.warn("Push notifications are not supported in this browser.");
  }
});
