import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "content"];
  static values = { voted: String, reviewed: String, activityType: String, activityMessage: String, trackableType: String, trackableId: String };

  connect() {
    // Initialize modal only once when the controller connects
    if (this.modalTarget) {
      this.bootstrapModal = new bootstrap.Modal(this.modalTarget);
      if (this.votedValue === "true" && this.reviewedValue === "false") {
        this.bootstrapModal.show();
      }
    }
  }

  open() {
    if (this.bootstrapModal) {
      this.bootstrapModal.show();
    }
  }

  close() {
    if (this.bootstrapModal) {
      this.bootstrapModal.hide();
      if (this.votedValue === "true" && this.reviewedValue === "false") {
        this.recordIgnoreReview()
      }
    }
  }

  validateSubmit() {
    if (this.bootstrapModal && this.contentTarget.value.length > 0 ) {
      this.bootstrapModal.hide();
    }
  }

  recordIgnoreReview() {
    fetch('/user_activities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        user_activity: {
          message: this.activityMessageValue,
          activity_type: this.activityTypeValue,
          trackable_type: this.trackableTypeValue,
          trackable_id: this.trackableIdValue
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log("Review recorded");
    })
    .catch((error) => {
      console.error("Error review");
    });
  }
}
