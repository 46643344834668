import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";
import { registerServiceWorkerAndSubscribe } from '../application';

export default class extends Controller {
  static targets = ["modal"];
  static values = { shortcut: String, activityType: String, activityMessage: String };

  connect() {
    // Initialize modal only once when the controller connects
    if (this.modalTarget) {
      this.bootstrapModal = new bootstrap.Modal(this.modalTarget);
      if (this.shortcutValue === "true") {
        this.bootstrapModal.show();
      }
    }
  }

  open() {
    if (this.bootstrapModal) {
      this.bootstrapModal.show();
    }
  }

  closeModal(customMessage = "Don't allow / close") {
    if (this.bootstrapModal) {
      this.bootstrapModal.hide();
      if (this.shortcutValue === "true") {
        this.recordShortcut(customMessage);
        this.shortcutValue = "false"
      }
    }
  }

  close() {
    this.closeModal("Don't Allow")
  }

  freeAlert() {
    this.closeModal("Tap Free Alert")
  }

  openBrowser() {
    this.closeModal("Open Browser")
  }

  recordShortcut(customMessage = "Don't allow / close") {
    fetch('/user_activities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        user_activity: {
          message: customMessage,
          activity_type: this.activityTypeValue
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log("Shortcut recorded");
    })
    .catch((error) => {
      console.error("Error shortcut");
    });
  }

  async callRegisterServiceWorker() {
    // Calling the async function from application.js
    try {
      await registerServiceWorkerAndSubscribe();
      console.log("Service Worker and subscription process initiated.");
    } catch (error) {
      console.error("Error calling registerServiceWorkerAndSubscribe:", error);
    }
  }

  isInstagramWebView() {
    return /Instagram/.test(navigator.userAgent);
  }
}
